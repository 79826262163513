.listing-card {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease;
  background: white;
}

.listing-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background: #f8f8f8;
}

.listing-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listing-content {
  padding: 1rem;
}

.listing-title {
  margin: 50px 0 0.5rem;
  font-size: 1.1rem;
  color: #333;
}

.listing-price {
  color: #2d6a4f;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.listing-location {
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.listing-category {
  display: inline-block;
  background: #f0f0f0;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.listing-description {
  color: #666;
  font-size: 0.9rem;
  line-height: 1.4;
  margin: 0.5rem 0;
}

.listing-features {
  display: flex;
  gap: 1rem;
  font-size: 0.8rem;
  color: #444;
  margin-top: 0.8rem;
}

.listing-features span {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}


/* Reviews Section Styles */
.reviews-section {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.star-rating {
  color: #ffd700;
  font-size: 1.2rem;
  margin-right: 8px;
}

.star {
  position: relative;
  display: inline-block;
}

.star.half::before {
  position: absolute;
  content: '★';
  width: 50%;
  overflow: hidden;
  color: #ffd700;
}

.star.empty {
  color: #ddd;
}

.review-count {
  font-size: 0.9rem;
  color: #666;
}

.review-item {
  margin: 8px 0;
  padding: 8px;
  background: #f5f5f5;
  border-radius: 4px;
}

.review-rating {
  color: #ffd700;
  font-weight: bold;
}

.review-comment {
  color: #666;
  font-size: 0.9rem;
}
