.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-block-end: 0;
}

.text {
  width: 100%;
  margin-block-start: 20px;

  &:first-child {
    margin-block-start: 0;
  }
}

.alignLeft {
  text-align: start;
}

.alignCenter {
  text-align: center;

  & > ul,
  & > ol {
    text-align: start;
    width: fit-content;
    /* center */
    margin-inline-start: auto;
    margin-inline-end: auto;
  }

  & > p {
    /* center */
    margin-inline-start: auto;
    margin-inline-end: auto;
  }
}

.alignRight {
  text-align: end;
  & > ul,
  & > ol {
    text-align: start;
    width: fit-content;
    /* push to right */
    margin-inline-start: auto;
  }

  & > p {
    /* push to right */
    margin-inline-start: auto;
  }
}
