@import '../../../../styles/customMediaQueries.css';
.root {
  /* fallback option */
  /* use break-word if available */
  hyphens: auto;
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-block-end: 0;
}

.text {
  width: 100%;

  &:first-child {
    margin-block-start: 0;
  }

  /* we style global elements only because they are generated by markdown processor */
  & a {
    color: var(--colorGrey700);

    &:hover {
      color: var(--marketplaceColor);
      text-decoration: none;
    }
  }

  & ol {
    margin-block-start: 0px;
    padding-inline-start: 0px;
  }

  & li {
    font-size: 16px;
    font-weight: var(--fontWeightRegular);
    color: var(--colorGrey700);
    padding: 0px;
    list-style-position: inside;
    @media (--viewportMedium) {
      font-size: 18px;
    }
  }

  & p {
    padding: 0px;
  }
}
