.root {
  padding: 4rem 0;
  background-color: var(--color-secondary);
}

.content {
  max-width: 1200px;
  margin: 0 auto;
}

.testimonialList {
  display: grid;
  grid-gap: 2rem;
  margin-top: 2rem;
}

.testimonialItem {
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.quote {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--color-text);
}

.author {
  margin-top: 1rem;
  font-weight: bold;
  color: var(--color-primary);
}
