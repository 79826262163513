/* AllListingsPage.css */
.all-listings-page {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;
}

.all-listings-title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
  color: #333;
}

.listings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.listing-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
}

.listing-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.listing-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.listing-card-content {
  padding: 16px;
}

.listing-card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

.listing-card-price {
  font-size: 16px;
  color: #666;
}

.listing-card-description {
  font-size: 14px;
  color: #888;
  margin-top: 8px;
}
/* AllListingsPage.css */
@media (max-width: 768px) {
  .listings-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
  }
}

@media (max-width: 480px) {
  .listings-grid {
    grid-template-columns: 1fr;
  }
}
