@import '../../../../styles/customMediaQueries.css';

.root {
  display: inline-block;

  &:first-child .link {
    padding-inline-start: 0px;
  }

  &:last-child .link {
    padding-inline-end: 0px;
  }
}

.link {
  padding: 12px 10px;

  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    padding: 12px 12px;
  }
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-block-end: 0;
}

.text {
  width: 100%;
  margin-block-start: 20px;

  &:first-child {
    margin-block-start: 0;
  }
}
